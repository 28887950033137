<script setup>
import ButtonSmall from '@/Components/Button/Small.vue';
import TinyDetailsCard from '@/Components/Flexer/TinyDetailsCard.vue';
import { Link } from '@inertiajs/vue3';
defineProps({
    application: Object,
});
const emits = defineEmits(['buttonClicked']);
</script>
<template>
    <div
        class="relative grid items-center w-full grid-cols-6 gap-10 py-3 pl-12 overflow-hidden border-t cursor-pointer border-blue/30"
    >
        <div class="absolute left-0 w-1 rounded top-3 bottom-3 bg-orange"></div>

        <div class="col-span-3">
            <Link :href="route('location.flexers.show', { id: application.flexer.id })" class="flex-1">
                <TinyDetailsCard pink image list :flexer="application.flexer"> </TinyDetailsCard>
            </Link>
        </div>

        <span class="text-base text-right">€ 15,70</span>

        <span class="text-base text-right">12 km</span>

        <ButtonSmall class="self-center max-w-35" @click="emits('buttonClicked')">
            {{ $t('Approve') }}
        </ButtonSmall>
    </div>
</template>
